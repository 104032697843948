/* new navbar */

@media (max-width: 768px) {
  .disable-on-mobile {
    display: none !important;
  }
}

.nav-main {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  z-index: 1030;
  background: white;
  border-bottom: 1px solid #E2E2E2;

  @media (max-width: 768px) {
    box-shadow: 5px 1px 10px 1px #a8a8a8;
  }

  .navbar-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    max-width: 800px;
    margin: 0 auto;
    padding: 0;

    .searchBtn {
      margin-right: 55px;
    }

    .navbar-brand {
      float: none;
      height: fit-content;
      padding: 10px 15px;

      @media (max-width: 768px) {
        width: 100%;
      }

      .brand {

        img {
          width: 50px;
          padding: 0;
        }
      }

    }

    li {
      display: flex;
      align-items: center;

    }

    .nav-links {
      display: flex;
      padding: 0 15px;
      flex-direction: row-reverse;
      width: 100%;

      .nev-links-container {
        display: flex;
        width: auto;
      }
    }
  }

  .Login {
    .ant-dropdown-trigger {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
      }
    }
  }

}

.search-view-mobile {
  top: 60px;
  position: fixed;
  width: 100% !important;
  z-index: 1030;
  padding: 0 5px;
}

.Login {
  align-items: center;
  margin-left: 10px;

  .facebook_login {
    height: auto;
    margin: 10px 0;
  }
}

.navbar-dropdown {

  .ant-dropdown-menu-item-divider {
    background-color: black;
  }

  .ant-dropdown-menu-item {
    border-bottom: 3px solid transparent;

    &:hover {
      background-color: transparent;
      border-bottom-color: #175968;
      color: #175968;

      a {
        color: #175968;
      }
    }

    a {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 5px;
        position: relative;
        top: -2px;
      }
    }
  }

  @media (max-width: 768px) {
    z-index: 1110 !important;
    width: 230px;
    background-color: #E2E2E2;

    .ant-dropdown-menu {
      background-color: #E2E2E2;
    }
  }
}

/* end of new navbar*/

a {
  color: #444444;
  text-decoration: none !important;
}

body {
  background-color: white;
  height: 100%;
}

@font-face {
  font-family: "Arcon-rounded";
  src: url(../src/content/font/Acron/arcon-rounded-regular.eot);
  src: url(../src/content/font/Acron/arcon-rounded-regular.eot?#iefix) format('embedded-opentype'), url(../src/content/font/Acron/arcon-rounded-regular.woff) format('woff'), url(../src/content/font/Acron/arcon-rounded-regular.ttf) format('truetype'), url(../src/content/font/Acron/arcon-rounded-regular.svg) format('svg');
}

.app-link {
  background-color: black;
  color: white;
  padding: 1rem;
  margin-bottom: 2rem;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.app-link a {
  color: #d8d8d8;
  text-decoration: underline !important;
}

.app-link a:hover {
  color: white;
}

#fb-root {
  display: inherit;
}

.searchColor a {
  color: #2D708E;
}

.empty {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 100px;
  color: #e1e1e1;
}

.empty_similar {
  position: relative;
  display: flex;
  font-size: 100px;
  color: #e1e1e1;
}

.similar_pastas {
  margin-top: 5px;
  padding-top: 5px;
}

.display_none {
  opacity: 0;
}

.pasta_pill {
  margin-bottom: 10px !important;
}

.data {
  font-size: 12px;
  padding-left: 3px;
  font-style: italic;
  text-align: right;
}

.padding_top_20 {
  padding-top: 20px;
}

#pcontent {
  padding: 2em 4em;
}

.imgUserProfile {
  width: 100px;
  height: 100px;
}

.margin-top {
  margin-top: 10px;
}

.text-underline-hover:hover {
  text-decoration: underline !important;
}

.userProfile {
  display: flex;
  flex-direction: row;
}

.small-font {
  font-size: 11px;
  vertical-align: middle;
  align-items: center;
}

.no-margin-bottom label {
  margin-bottom: 0;
  margin-top: 10px;
}

.flex-column {
  flex-direction: column;
}

.dropZoneDiv > div {
  width: 100% !important;
  height: 40px !important;
  padding: 10px;
  color: #8d8d8d;
}

.top_zero {
  top: 0;
}

.navbar-nav {
  align-items: center !important;
}

.x_button {
  padding: 6px 12px;
  background-color: transparent;
  border: 1px solid #cbcbcb;
  color: #cbcbcb;
  border-bottom-right-radius: 4px;
}

.profile_stats {
  width: inherit;
  padding: 1vh 1vw 0 1vw;
  border: 1px solid #bfbfbf;
  background-color: #EFEFEF;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
  box-shadow: 1px 0 1px 1px #b5b5b5;
}

.profile_stats_with_left_border {
  width: inherit;
  padding: 1vh 1vw 0 1vw;
  border: 1px solid #bfbfbf;
  background-color: #EFEFEF;
  border-radius: 4px;
  box-shadow: 0 0 1px 1px #b5b5b5;
}


.popUp img {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: -1px 0 1px 1px #b5b5b5;
}

.profile_score {
  display: flex;
  font-size: 18px;
}

.profile_userName {
  width: inherit;
  text-align: center;
  font-size: 20px;
  color: #4F4F4F;
  padding-bottom: 3px;
}

.searchBtn {
  margin: 0;
  background-color: transparent;
  border: 1px solid #ddd;
}

.break_word {
  word-wrap: break-word;
  white-space: normal;
}

.glyphicon {
  position: inherit;
}

.container {
  max-width: 800px;
  font-family: "Arcon-rounded", sans-serif;
}

.StyledFbComments {
  color: #4F4F4F !important;
}

.fullScreen {
  width: 100vw;
  height: 100vh;
}

.alert_custom {
  top: 0;
  z-index: 1080;
  padding: 30px 20px;
  position: fixed;
  width: 100%;
  max-width: 770px;
  border: 1px solid;
  left: inherit;
}

.alert_warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert_success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert_danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.absolute {
  position: absolute;
}

.transparent {
  background: transparent;
}

.top_separator {
  border-top: 4px double;
}

.text-color {
  color: #4F4F4F;
}

.margin-bottom {
  margin-bottom: 5px;
}

.pasta {
  font-size: 16px;
  background-color: #FCFCFC;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  box-shadow: 1px 1px 1px #E5E5E5;
  margin-bottom: 3vh;
}

.focus_pilled_pasta {
  border: 2px solid rgba(26, 78, 101, 0.46);
  box-shadow: 1px 1px 1px rgba(43, 212, 249, 0.15);
}

.pasta_gold {
  background-color: #FFFBF4;
  border: 1px solid #E8DAC9;
  box-shadow: 1px 1px 1px #E8DAC9;
}

.pasta_gold .containerStatsTitle {
  background-color: #EDE1C2;
}

.pasta_gold .pasta_title a {
  color: #967B3C
}

.pasta_gold .border-top {
  border-color: #E8DBC2;
}

.pasta_content {
  margin-bottom: 3vh;
  padding: 2em 4em;
  color: #4F4F4F;
  font-size: 15px;
  line-height: 1.42;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
  white-space: normal;
}

.pasta_pill .pasta_content {
  padding: 15px 20px;
}

.pasta_title {
  display: flex;
  flex-direction: column;
  padding: 0 0.5em 0 0.5em;
  font-size: 20px;
  font-weight: bold;
  width: inherit;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: normal;
}

.pasta_title > a {
  padding: 10px;
}

.stats {
  display: inline-block;
  float: right;
  border-top-right-radius: 4px;
  padding: 1em 1em 0 1em;
}

.imagePasta img {
  width: inherit;
}

#imageBox {
  height: 100px;
  width: 100%;
}

.imgBackdrop {
  background: rgba(0, 0, 0, 0.52);
  height: 250px;
}

.alignVerticalMiddle {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  flex-grow: 1;
}

.paddingNavbarLink a {
  padding: 15px;
  display: flex;
  align-items: center;
}

.Login .dropdown-toggle {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.Login .dropdown-toggle > span {
  display: flex;
  align-items: center;
}

.padding-right {
  padding-right: 4px;
}

#textImgCover {
  width: 100%;
  text-align: center;
  color: white;
  opacity: 1;
  font-size: 18px;
  padding: 0 30px;
}

.containerStatsTitle {
  width: 100%;
  background-color: #EFEFEF;
  display: block;
}

.flex-row {
  flex-direction: row;
}

.dropdown-toggle {
  padding: 10px 0;
}

.pasta_author {
  color: #2e6da4;
  font-style: italic;
  display: inline-block;
  font-size: 12px;
  padding: 4px 0 0 0;
  border-top: 1px solid #d4d4d4;
  max-width: 180px;
}

.pasta_author a {
  text-decoration: underline;
}

.pasta_tags {
  font-style: italic;
  font-size: 85%;
  color: #6D6D6D;
  text-align: right;
  padding: 1px 4px 3px 0;
}

.navbar {
  margin-bottom: 0;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.brand img {
  width: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.marginTop {
  margin-top: 80px;
}

.navbar_links {
  display: flex;
  flex-direction: row-reverse;
  flex-basis: 100%;
}

.navbar_links ul {
  display: flex;
  flex-direction: row;
  float: right;
  margin-bottom: 0;
}

.navbar_links ul > li {
  display: inline-block;
  padding: 10px;
  vertical-align: middle;
}

.navbar_links ul > li > a {
  font-size: 18px;
}

.navbar .container-fluid .container {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;
}

.searchBox .search {
  padding: 5px;
  border: 1px solid #A0A0A0;
  border-radius: 4px;
  vertical-align: middle;
  height: 100%;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  display: inline-block;

}

.navbar-default .navbar-nav > li > a {
  min-height: 55px;
}

.navbar-default .navbar-nav > li > .activeLink,
.activeLink {
  color: #1b6d85;
  border-bottom: 3px solid #1b6d85;
}

.navbar-default .navbar-nav > li > .activeLink:hover,
.activeLink:hover,
.navbar-default .navbar-nav > li > .activeLink:active,
.activeLink:active,
.navbar-default .navbar-nav > li > .activeLink:focus,
.activeLink:focus {
  color: #175968;
  border-bottom: 3px solid #175968;
}

.navbar-right {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  margin-right: 0;
}

.searchBox {
  vertical-align: middle;
}

.searchBox .icon {
  background-color: white;
  font-size: 18px;
  height: 100%;
  left: -5px;
  vertical-align: middle;
  position: relative;
  border: 1px solid #A0A0A0;
  border-radius: 4px;
  color: #A0A0A0;
}

.hiddenInput {
  width: 0;
  display: none !important;
}

.showInput {
  -webkit-animation-name: showInput;
  -moz-animation-name: showInput;
  -o-animation-name: showInput;
  animation-name: showInput;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  position: relative;
  display: block;
}

.hideInput {
  position: relative;
  -webkit-animation-name: hideInput;
  -moz-animation-name: hideInput;
  -o-animation-name: hideInput;
  animation-name: hideInput;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  margin-top: -100%;
  border-left: 1px solid black;
  display: none;

}

.flex {
  display: flex;
}

.flex-rowreverse {
  flex-direction: row-reverse;
}

.views {
  vertical-align: middle;
  flex-flow: column-reverse;
  display: flex;
  color: #989898;
}

.positiveStat {
  color: #7BC67B;
}

.negativeStat {
  color: #C47B7F;
}

.neutralStat {
  color: #70919E;
}

.containerStatsTitle .display_block {
  width: 100%;
}

.display_flex {
  display: flex;
}

.half_width {
  width: 50%;
  padding: 0 5px;
}

.containerStatsTitle .pasta_title {
  width: inherit;
}

.navbar-collapse {
  display: inline-block;
  width: 100%;
}

nav .container {
  display: flex;
}

.search {
  height: 100%;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  width: 45%;
}

.search input {
  height: 30px;
  border: 1px solid #b7b7b7;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  min-width: 150px;
  width: 100%;
}

.searchResults {
  display: block;
  padding-top: 80px;
  width: 100%;
  z-index: 1030;
}

.searchResult {
  border-bottom: 1px solid rgba(184, 184, 184, 1);
}

.searchResult a {
  width: inherit;
  display: block;
  padding: 20px;
}

.searchResult a:hover {
  background-color: #d0d0d0;
  color: #175968;
}

.boxCentered {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.previewBox img {
  width: 200px;
}

.bgLightGrey {
  background-color: #f8f8f8;
}

.fixedBottom {
  bottom: 0;
  position: fixed;
}

.infoBottom {
  right: 0;
  padding-right: 5px;
}

.badge {
  padding: 0 0.4em 1em 0.4em;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 90%;
  display: flex;
  flex-direction: column;
}

.badgeComments > div {
  display: inline-block;
}

.badgeComments {
  background-color: #82A28B;
  color: white;
}

.badgeVote {
  background: none;
  color: #70919E;
}

.badgeViews {
  background: none;
  color: #8a6d3b;
}

.text-italic {
  font-style: italic;
}

.padding-top {
  padding-top: 10px;
  padding-right: 5px;
}

.badgeVote .glyphicon {
  color: #70919E;
}

.badge .icon,
.badge .glyphicon {
  margin-bottom: 4px;
  position: inherit;
}

.link_underline:hover {
  text-decoration: underline;
}

.link_color {
  color: #ababab;
}

.link_delete {
  color: #A8484E;
}

.link_delete:hover,
.link_delete:focus,
.link_delete:active {
  color: #703034;
}

.noBg {
  background: none;
}

.noBoxShadowHover:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.fixedTop {
  position: fixed;
}

.disableOnDesktop {
  display: none;
}

.commentsCounter {
  display: inline-block;
  padding-left: 5px;
}

.commentsCounter .badge {
  padding: 8px;
  display: inline-block;
  position: relative;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.statsDiv {
  display: flex;
  flex-direction: row-reverse;
}

.voteStatTitle {
  display: inline-block;
}

.Login {
  position: relative;
  display: flex;
}

.Login a {
  display: block;
  width: 100%;
}

.dropdown-menu li a {
  padding: 0;
}

.dropdown-menu li a a,
.dropdown-menu li a button {
  display: flex;
  padding: 3px 15px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: normal;
}

.dropdown-menu li a button {
  background: transparent;
  width: 100%;
  border: none;
  text-align: left;
}

.dropdown-menu .icon {
  margin-right: 5px;
  position: relative;
}

.imgUser {
  width: 30px;
  min-width: 30px;
  height: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-right: 2px;
  margin-left: 2px;
}

.facebook_login {
  width: 100%;
  max-width: none;
  background-color: #2e6da4;
  color: white;
  white-space: normal;
  display: flex;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  .icon {
    margin-right: 5px;
  }
}

.facebook_login:hover,
.facebook_login:active,
.facebook_login:focus {
  color: white;
  background-color: #2A6291;
}

.btnMore {
  background: none;
  color: #1b6d85;
  padding: 0;
  vertical-align: inherit;
  white-space: normal;
}

.btnMore:hover {
  text-decoration: underline;
  color: #134C5B;
}

.btnMore:focus,
.btnMore:active {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btnMore .icon,
.noCoverPhotoMoreBtn .icon {
  font-size: 80%;
}

.noCoverPhotoMoreBtn {
  color: #7ae3f2;
  padding: 0 10px;
  vertical-align: text-bottom;
}

.noCoverPhotoMoreBtn:hover {
  text-decoration: underline;
  color: #6DC9D6;
}

.noCoverPhotoMoreBtn:focus,
.noCoverPhotoMoreBtn:active {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.imageDiv {
  height: 250px;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.pastaVote {
  padding-top: 10px;
  padding-left: 5px;
}

.pastaVote .liked {
  background-color: #7BC67B !important;
  color: white !important;
}

.pastaVote .like {
  color: #7BC67B;
  background: none;
  border: 1px solid #7BC67B;
}

.pastaVote .disliked {
  color: white !important;
  background: #C47B7F !important;
}

.pastaVote .dislike {
  color: #C47B7F;
  background: none;
  border: 1px solid #C47B7F;
}

.lightPadding-left {
  padding-left: 5px;
}

.border-top {
  border-top: 1px solid;
}

.light-grey {
  border-color: #dadada;
}

.fullWidth {
  width: 100%;
}

.fullWidth input {
  width: 100%;
}

.scroll_disabled ~ .container {
  overflow: hidden;
  height: 97vh;
}

.scroll_disabled #searchContainer {
  overflow-y: scroll;
  overflow-x: hidden;
}

#searchContainer {
  height: 100%;
  z-index: 1020;
}

.backdrop {
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.73);
  z-index: 1010;
  position: fixed;
  min-height: 100vh;
}

.maxWidth {
  max-width: 768px;
}

/* role colors */

.greyNorm {
  color: #5e5e5e;
}

.greyNorm:hover,
.greyNorm:active,
.greyNorm:focus {
  color: #5e5e5e;
}

.greenMode {
  color: #5AAD5B;
}

.greenMode:hover,
.greenMode:active,
.greenMode:focus {
  color: #5AAD5B;
}

.redRoot {
  color: #A8484E;
}

.redRoot:hover,
.redRoot:active,
.redRoot:focus {
  color: #A8484E;
}

@media (min-width: 992px) {
  .alert_custom {
    left: auto !important;
  }

  .maxWidthPicRel {
    max-width: 650px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .maxWidth {
    max-width: 718px;
  }

  .maxWidthPicRel {
    max-width: 600px;
  }

  .alert_custom {
    left: auto !important;
  }

  .facebook_login {
    padding: 6px;
  }
}

@media (min-width: 400px) and (max-width: 768px) {

  .marginTop {
    margin-top: 80px;
  }

  .searchInput {
    padding: 20px;
    min-height: 35px;
    border: 1px solid #a1a1a1;
  }

  .no_padding {
    padding: 0;
  }

  .searchBtn {
    margin: 7px 2px;
    color: rgb(51, 51, 51);
  }

  .alert_custom {
    left: 0;
  }

  .maxWidthPicRel {
    max-width: none;
  }

  .imgBackdrop {
    background: rgba(0, 0, 0, 0.60);
  }

  #textImgCover {
    max-width: 768px;
    text-align: center;
    color: white;
    opacity: 1;
    font-size: 14px;
    padding: 0 8px;
  }

  .disableOnDesktop {
    display: inline-block;
    float: right;
  }

  .alwaysOnTop {
    z-index: 1050;
  }

  .brandSpan {
    width: 100%;
  }

  .disableOnMobile {
    display: none;
  }

  .navbar-collapse {
    display: none;
  }

  .search {
    width: auto;
  }

  .searchResults {
    padding-top: 100px !important;
    z-index: 1020;
    overflow: scroll;
  }

  .display_block {
    display: block;
    width: inherit;
  }

  .containerStatsTitle {
    display: block;
  }

  .container {
    padding: 0 5px;
  }

  .navbar-collapse {
    display: none;
  }

  .pasta_title {
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em 0.8em 5px;
    width: inherit;
  }

  .pasta_title > a {
    padding: 5px;
  }

  .pasta_author {
    display: inline-block;
    font-size: 12px;
    padding: 4px 0 0 0;
    border-top: 1px solid #d4d4d4;
    max-width: 180px;
  }

  .disableOnMobile {
    display: none;
  }

  .pasta_author a {
    color: #2e6da4;
    font-style: italic;
  }

  .pasta_content {
    padding: 15px;
    font-size: 16px;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* css-3 */
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
    white-space: normal;
  }

  .btnMore {
    background: none;
    color: #1b6d85;
    padding: 0 5px;
    vertical-align: inherit;
    white-space: normal;
  }

  .btnMore:hover {
    text-decoration: underline;
    color: #134C5B;
  }

  .btnMore:focus,
  .btnMore:active {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .btnMore .icon {
    font-size: 80%;
  }

  .statsDiv .icon {
    font-size: 16px;
    display: flex;
    flex-flow: column;
    bottom: 5px;
  }

  .statsDiv .badge {
    padding: 0.5em 0.5em;
  }

  .badgeVote .icon {
    color: #70919E;
  }

  .navbar-nav {
    margin: 7.5px 0;
  }

  .navbar-right {
    align-items: baseline;
    display: flex;
    flex-direction: column;
  }

  .navbar-right li {
    width: 100%;
    vertical-align: middle;
  }

  .navbar-default {
    border-color: #DBDBDB;
  }

  .navbar {
    box-shadow: 5px 1px 10px 1px #a8a8a8;
    border-bottom: 1px solid #aaaaaa;
    padding: 0;
    height: auto;
    min-height: 55px;
  }

  .navbar .navbar-collapse {
    background-color: #ddd;
    border-top: 1px solid #b6b6b6;
    margin: 0;
    max-height: none;
  }

  .navbar-default {
    padding-top: 0;
  }

  .navbar-default .navbar-nav > li > a {
    color: #575757;
  }

  .dropdown-menu > li > a > a,
  .dropdown-menu li a button {
    padding: 0;
    color: #575757;
  }

  .navbar-default .container {
    display: block;
    padding: 0;
    width: 100%;
  }

  .dropdown-toggle:hover,
  .dropdown-toggle,
  .dropdown-toggle:active,
  .dropdown-toggle:focus {
    background-color: #dddddd !important;
  }

  .boxCentered img {
    width: 100% !important;
  }

  div[class='dropdown open'] {
    background-color: #D3D3D3;
    color: #575757;
  }

  .Login {
    width: 100%;
    border-top: 1px solid #b8b8b8;
    margin-left: 0;
  }

  .facebook_login {
    width: 100%;
    max-width: none;
    margin-top: 15px;
    background-color: #2e6da4;
    color: white;
    white-space: normal;
    display: flex;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .facebook_login:hover,
  .facebook_login:active,
  .facebook_login:focus {
    color: white;
    background-color: #2A6291;
  }

  .navbar-nav,
  .navbar-nav > span {
    flex-direction: column;
  }

  .navbar-nav a {
    width: 100%;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .text-center-mobile {
    text-align: center;
  }

}


@media (max-width: 400px) {

  .marginTop {
    margin-top: 80px;
  }

  .searchInput {
    padding: 20px;
    min-height: 35px;
    border: 1px solid #a1a1a1;
  }

  .navbar {
    min-height: 50px;
    display: block;
  }

  .searchBtn {
    margin: 7px 2px;
  }

  .maxWidthPicRel {
    max-width: none;
  }

  .boxCentered img {
    width: 100% !important;
  }

  .alert_custom {
    left: 0;
  }

  .imgBackdrop {
    background: rgba(0, 0, 0, 0.65);
  }

  #textImgCover {
    max-width: 768px;
    text-align: center;
    color: white;
    opacity: 1;
    font-size: 13px;
    padding: 0 8px;
  }

  .disableOnDesktop {
    display: inline-block;
    float: right;
  }

  .alwaysOnTop {
    z-index: 1050;
  }


  .brandSpan {
    width: 100%;
  }

  .disableOnMobile {
    display: none;
  }

  .navbar-collapse {
    display: none;
  }

  .search {
    width: auto;
  }

  .searchResults {
    padding-top: 100px !important;
    z-index: 1020;
    overflow: scroll;

  }

  .brand img {
    width: 50px;
  }

  .fullWidth input {
    width: 100%;
  }

  .display_block {
    display: block;
    width: inherit;
  }

  .containerStatsTitle {
    display: block;
  }

  .pasta_title {
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em 0.8em 5px;
    width: inherit;
    font-size: 14px;
  }

  .pasta_title > a {
    padding: 5px;
  }

  .pasta_author {
    display: inline-block;
    font-size: 12px;
    padding: 4px 0 0 0;
    border-top: 1px solid #d4d4d4;
    max-width: 150px;
  }

  .pasta_author a {
    color: #2e6da4;
    font-style: italic;
  }

  .pasta_content {
    padding: 15px;
    font-size: 16px;
    white-space: normal;
  }

  .btnMore {
    background: none;
    color: #1b6d85;
    padding: 0 5px;
    vertical-align: inherit;
    white-space: normal;
  }

  .btnMore:hover {
    text-decoration: underline;
    color: #134C5B;
  }

  .btnMore:focus,
  .btnMore:active {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .btnMore .icon {
    font-size: 80%;
  }

  .stats {
    padding-right: 0;
    padding-left: 5px;
  }

  .statsDiv .icon {
    font-size: 16px;
    display: flex;
    flex-flow: column;
    bottom: 5px;
  }

  .statsDiv .badge {
    padding: 0.5em 4px;
  }

  .badgeVote .icon {
    color: #70919E;
  }

  .badgeVote,
  .badgeComments {
    border-right: none;
  }

  .container {
    padding: 10px 5px;
  }

  .navbar-right {
    align-items: baseline;
    display: flex;
    flex-direction: column;
  }

  .navbar-nav {
    margin: 7.5px 0;
  }

  .navbar-right li {
    width: 100%;
    vertical-align: middle;
  }

  .navbar-default {
    border-color: #DBDBDB;
  }

  .navbar {
    padding-bottom: 0;
    box-shadow: 5px 1px 10px 1px #a8a8a8;
    border-bottom: 1px solid #aaaaaa;
  }

  .navbar .navbar-collapse {
    background-color: #ddd;
    border-top: 1px solid #b6b6b6;
    max-height: none;
  }

  .navbar-default {
    padding-top: 0;
  }

  .navbar-default .navbar-nav > li > a {
    color: #575757;
  }

  .dropdown-menu > li > a > a,
  .dropdown-menu li a button {
    padding: 0;
    color: #575757;
  }

  .navbar-default .container {
    display: block;
    padding: 0;
  }

  .dropdown-toggle:hover,
  .dropdown-toggle,
  .dropdown-toggle:active,
  .dropdown-toggle:focus {
    background-color: #dddddd !important;
  }

  div[class='dropdown open'] {
    background-color: #D3D3D3;
    color: #575757;
  }

  .Login {
    width: 100%;
    border: none;
    margin-left: 0;
  }

  .facebook_login {
    width: 100%;
    max-width: none;
    margin-top: 15px;
    background-color: #2e6da4;
    color: white;
    white-space: normal;
    display: flex;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .facebook_login:hover,
  .facebook_login:active,
  .facebook_login:focus {
    color: white;
    background-color: #2A6291;
  }

  .container > .navbar-collapse {
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-nav,
  .navbar-nav > span {
    flex-direction: column;
  }

  .navbar-nav a {
    width: 100%;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .text-center-mobile {
    text-align: center;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {

  @media (min-width: 768px) {
    display: none;
  }

  position: fixed;
  width: 30px;
  height: 25px;
  right: 15px;
  top: 17px;
  z-index: 1040 !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #E2E2E2;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #444;
  padding-top: 0.8em;
  position: relative;

  li {
    a {
      width: 100%;
    }
  }

  .Login {
    .ant-dropdown-trigger {
      margin-top: 15px;
      color: #444;
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
      }

      .imgUser {
        margin-right: 5px;
      }
    }
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.ant-select-dropdown-menu-item-active {
  color: white !important;

  .anticon {
    color: white !important;
  }
}