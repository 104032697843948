
.ant-form-item-no-colon {
  width: 100%;
  display: flex;
  text-align: left;
  line-height: 20px;

  &::before {
    height: fit-content;
  }

  .form-label-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.dropZoneDiv {
  line-height: initial;
}

.dropzone-description {

}

.hint-text {

  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.ant-select-search__field__mirror {
  @media (max-width: 768px) {
    min-width: 2rem;
    margin-left: 5px;
  }
}

#tags {

  @media (max-width: 768px) {
    padding: {
      right: 0;
      left: 0;
    }
  }

}